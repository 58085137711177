// Auto-generated language file from /opt/whdata/installedmodules/petit_natura.20240708T140214.239Z/webdesigns/petit_natura/petit-natura.lang.json
var registerTexts = require("@mod-tollium/js/gettid").registerTexts;
registerTexts("petit_natura","de",{"js":{"arrival":"Wählen Sie ein Ankunftsdatum aus","basedon":"Die Preise basieren auf einem Doppelzimmer, inklusive Frühstück, Getränkepaket und Ortstaxe.","book":"Jetzt buchen","checkin":"Einchecken","checkout":"Auschecken","clicktobook":"Klicken Sie auf 'Jetzt buchen'","departure":"Wählen Sie ein Abreisedatum aus","lang":"de","overview":"Überblick","package":"Zimmer","price":"Preis","resetrange":"Klare auswahl","view":"Betrachten Sie Ihren Aufenthalt im Überblick"}});
registerTexts("petit_natura","en",{"js":{"arrival":"Select an arrival date","basedon":"Prices are based on a double room, including breakfast, drinks package and local tax.","book":"Book now","checkin":"Check-in","checkout":"Check-out","clicktobook":"Click 'Book Now' to reserve","departure":"Select a departure date","lang":"enGB","overview":"Overview","package":"Room","price":"Price","resetrange":"Clear selection","view":"View your stay in the overview"}});
registerTexts("petit_natura","es",{"js":{"arrival":"Seleccione una fecha de llegada","basedon":"Los precios se basan en una habitación doble, incluye desayuno, paquete de bebidas e impuestos locales.","book":"Reserve ahora","checkin":"Registro","checkout":"Salida","clicktobook":"Haz clic en 'Reserve ahora'","departure":"Seleccione una fecha de salida","lang":"es","overview":"Resumen","package":"Habitación","price":"Precio","resetrange":"Clara selección","view":"Vea su estancia en el resumen"}});
registerTexts("petit_natura","fr",{"js":{"arrival":"Sélectionnez une date d'arrivée","basedon":"Les prix sont basés sur une chambre double, comprenant le petit-déjeuner, un forfait boissons et la taxe locale.","book":"Réservez maintenant","checkin":"Enregistrement","checkout":"Départ","clicktobook":"Cliquez sur 'Réservez maintenant' pour réserver","departure":"Sélectionnez une date de départ","lang":"fr","overview":"Aperçu","package":"Chambre","price":"Prix","resetrange":"Réinitialiser la sélection","view":"Consultez votre séjour dans l'aperçu"}});
registerTexts("petit_natura","it",{"js":{"arrival":"Seleziona una data di arrivo","basedon":"I prezzi si basano su una camera doppia, inclusa la colazione, un pacchetto bevande e le tasse locali.","book":"Prenota ora","checkin":"Check-in","checkout":"Check-out","clicktobook":"Clicca su 'Prenota ora' per prenotare","departure":"Seleziona una data di partenza","lang":"it","overview":"Panoramica","package":"Camera","price":"Prezzo","resetrange":"Reimposta selezione","view":"Visualizza il tuo soggiorno nella panoramica"}});
registerTexts("petit_natura","nl",{"js":{"arrival":"Selecteer een aankomst datum","basedon":"Prijzen zijn gebaseerd op een tweepersoonskamer, inclusief ontbijt, drankenpakket en lokale belasting.","book":"Boek nu","checkin":"Inchecken","checkout":"Uitchecken","clicktobook":"Klik 'Boek nu' om te reserveren","departure":"Selecteer een vertrek datum","lang":"nl","overview":"Overzicht","package":"Kamer","price":"Prijs","resetrange":"Reset selectie","view":"Bekijk uw verblijf in het overzicht"}});
// Adding dependency: /opt/whdata/installedmodules/petit_natura.20240708T140214.239Z/language/de.xml
// Adding dependency: /opt/whdata/installedmodules/petit_natura.20240708T140214.239Z/language/en.xml
// Adding dependency: /opt/whdata/installedmodules/petit_natura.20240708T140214.239Z/language/es.xml
// Adding dependency: /opt/whdata/installedmodules/petit_natura.20240708T140214.239Z/language/fr.xml
// Adding dependency: /opt/whdata/installedmodules/petit_natura.20240708T140214.239Z/language/it.xml
// Adding dependency: /opt/whdata/installedmodules/petit_natura.20240708T140214.239Z/language/default.xml
